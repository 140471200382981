@use "../designSystem/common/sassVars" as *;
@use "../components/export/tabs";
@use "../components/export/uktoprooms_tc";

.tabs {
	@extend %tabs;

	--tabs-display: flex;
}

.fogTopRooms {
	header {
		border-bottom: none;
	}

	&__availableCountry {
		margin-top: 10px;

		img {
			vertical-align: -2px;
			box-shadow: 0 0 0 1px var(--flag-outline);
		}
	}

	.outlinedWidget__body {
		padding-left: 8px;
		padding-right: 8px;
	}

	&__list>div {
		display: grid;
		grid-template-areas: "logo title title"
		"logo type cta"
		"logo offer cta";
		grid-template-columns: 63px 2fr 1fr;
		column-gap: 12px;

		&:last-child {
			margin-bottom: 20px;
		}

		&:not(:last-child) {
			margin-bottom: 14px;
			border-bottom: 1px solid var(--grey-200);
			padding-bottom: 14px;
		}

		.logo {
			grid-area: logo;

			img {
				vertical-align: top;
			}
		}

		b {
			grid-area: title;
			font-size: 16px;
			line-height: 18px;
		}

		small {
			grid-area: type;
			font-size: 10px;
			opacity: 0.6;
			display: block;
			margin-top: -5px;
		}

		p {
			grid-area: offer;
			margin: 0;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			align-self: flex-end;
			line-height: 20px;
		}

		.fogTopRooms__list__cta {
			grid-area: cta;
			align-self: flex-end;
		}
	}
}

.fogTopRooms__ukTC {
	@extend %top-rooms-uk-tc;
}
