@use "../../designSystem/common/sassVars" as *;
@use "../../designSystem/mixins/cross" as *;
@use "../../designSystem/exports/baseTextFormat";

%top-rooms-uk-tc {
	position: relative;
	margin-top: 20px;

	button {
		border: none;
		padding: 0;
		cursor: pointer;
		background-color: transparent;
	}

	&>button {
		font-size: 14px;
		text-decoration: underline;
	}

	div {
		display: none;
		position: absolute;
		top: 30px;
		right: 0;
		width: 600px;
		background-color: #fff;
		z-index: 6;
		padding: 0 20px 10px;
		border: 1px solid #b0b0b0;
		color: #000;
		text-align: left;

		@media (max-width: $scr-sm) {
			left: -20px;
			max-height: 90vh;
			overflow-y: scroll;
			right: -20px;
			width: auto;
		}

		&>button {
			@include cross(black, 15px, 1px);

			position: absolute;
			right: 20px;
			top: 20px;
		}

		p {
			@extend %base-text-format;
		}

	}

	.ukTC__header {
		margin-top: 20px;
	}

	&.active>div {
		display: block;
	}
}
