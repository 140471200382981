@use "../../designSystem/common/sassVars" as *;

%tabs {
	div {
		background-color: var(--grey-200);
		margin-bottom: 20px;
		display: flex;
		border-radius: var(--rad);
		padding: 2px;
		column-gap: 2px;

		@media (min-width: $scr-sm-min) {
			display: var(--tabs-display);
		}

		a {
			display: inline-block;
			line-height: 32px;
			font-size: 16px;
			flex: 1 0 0;
			border-radius: var(--rad);
			padding: 0 5px;
			text-align: center;
			color: rgb(0 0 0 / 70%);
			cursor: pointer;

			@media (min-width: $scr-sm-min) {
				padding: 0 20px;
				min-width: var(--tabs-min-width);
			}


			&.active {
				background-color: #fff;
				color: #000;
			}

			&.disabled {
				pointer-events: none;
				cursor: pointer;
				opacity: .2;
				transition: opacity .2s;
			}

			&:not(.active):hover {
				background-color: rgb(0 0 0 / 5%);
			}

			&:hover {
				text-decoration: none;
			}
		}
	}
}
