@use "../common/sassVars" as *;

%base-text-format {
	font-size: var(--text-size-m);
	line-height: var(--text-line-height-m);

	@media (min-width: $scr-xs-min) {
		font-size: var(--text-size-d);
		line-height: var(--text-line-height-d);
	}

	a {
		text-decoration: underline;
	}
}
